<template>
  <v-row>
    <v-col cols="12" sm="6" md="3" lg="3">
      <v-hover v-slot="{ hover }">
        <base-card
          class="pa-4 text-center align-center"
          :disabled="disableCards"
          @click="
            () => {
              if (!disableCards) {
                openReport();
              }
            }
          "
        >
          <v-icon large :color="disableCards ? '' : 'primary'">mdi-cart-variant</v-icon>
          <p class="mb-0 mt-2 text-12 grey--text text--darken-1" v-if="!hover">
            {{ order.ocId }}
          </p>

          <p
            class="mb-0 mt-2 text-12 primary--text text-decoration-underline"
            v-else
          >
            {{ $t("OrderDetails.invoice") }}
          </p>
        </base-card>
      </v-hover>
    </v-col>

    <v-col cols="12" sm="6" md="3" lg="3">
      <base-card class="pa-4 text-center align-center" :disabled="disableCards">
        <v-badge
          :color="disableCards ? 'grey' : 'primary'"
          :icon="confirmationIcon"
          bordered
          offset-x="10"
          offset-y="10"
        >
          <v-icon large :color="disableCards ? '' : 'primary'"
            >mdi-package</v-icon
          >
        </v-badge>

        <p
          class="mb-0 mt-2 text-12 grey--text text--darken-1"
          v-if="order.tblLgtEstatusOc"
        >
          {{ order.tblLgtEstatusOc.estatusOc }}
        </p>
      </base-card>
    </v-col>

    <v-col cols="12" sm="6" md="3" lg="3">
      <base-card
        class="pa-4 text-center align-center"
        @click="
          () => {
            if (
              order.tblLgtEstatusPago &&
              order.tblLgtEstatusPago.estatusPagoId == 'PP'
            ) {
              $router.push(`/orders/${order.ocId}/payments`).catch(()=>{});
            }
          }
        "
      >
        <v-badge
          color="primary"
          :icon="paymentIcon"
          bordered
          offset-x="10"
          offset-y="10"
        >
          <v-icon large color="primary">mdi-credit-card-outline</v-icon>
        </v-badge>

        <p
          class="mb-0 mt-2 text-12 grey--text text--darken-1"
          v-if="
            order.tblLgtEstatusPago &&
            order.tblLgtEstatusPago.estatusPagoId != 'PP'
          "
        >
          {{ order.tblLgtEstatusPago.estatusPago }}
        </p>

        <p
          class="mb-0 mt-2 text-12 primary--text text-decoration-underline"
          v-else
        >
          {{ $t("OrderDetails.payOnline") }}
        </p>
      </base-card>
    </v-col>

    <v-col cols="12" sm="6" md="3" lg="3">
      <base-card
        class="pa-4 text-center align-center"
        @click="
          () => {
            if (isAuthenticated) {
              $router.push(`/entities/${$route.params.entidadId}/account/orders/${order.ocId}/codi`).catch(()=>{});
            }else{
              $router.push(`/orders/${order.ocId}/codi`).catch(()=>{});
            }
          }
        "
      >
        <v-icon large color="primary">mdi-qrcode</v-icon>
        <p class="mb-0 mt-2 text-12 grey--text text--darken-1">
          {{ $t("OrderDetails.codi") }}
        </p>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import { mapState } from 'vuex';

const COFIRMATION_ICON = {
  "00": "mdi-clock-outline",
  "10": "mdi-check",
  "20": "mdi-check",
  "30": "mdi-check",
  "40": "mdi-check",
  "90": "mdi-close",
};

const PAYMENT_ICON = {
  "01": "mdi-check",
  "02": "mdi-close",
  "03": "mdi-credit-card-refund",
  "04": "mdi-clock",
  "PP": "mdi-clock",
};

export default {
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
    ocId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState({
      isAuthenticated: (state) => state.authentication.isAuthenticated,
    }),
    disableCards() {
      let estatusOcId = _.get(this.order, "tblLgtEstatusOc.estatusOcId");
      if (estatusOcId == "00" || estatusOcId == "90") {
        return true;
      }

      return false;
    },
    confirmationIcon() {
      let estatusOcId = _.get(this.order, "tblLgtEstatusOc.estatusOcId");

      return COFIRMATION_ICON[estatusOcId];
    },
    paymentIcon() {
      let estatusPagoId = _.get(this.order, "tblLgtEstatusPago.estatusPagoId");

      return PAYMENT_ICON[estatusPagoId];
    },
  },
  methods: {
    getColor(isConfirmed) {
      if (isConfirmed) {
        return "primary";
      }

      return "grey lighten-2";
    },
    getDate(d) {
      return moment(d).format("yyyy-MM-DD");
    },
    openReport() {
      const base = axios.defaults.baseURL;
    
      const params = new URLSearchParams({
        __id: "birtViewer",
        __report: "RPTBOCSDETLGC01.rptdesign",
        __masterpage: "true",
        __format: "HTML",
        __parameterpage: "false",
        pOcId: this.$route.params.ocId,
        pEntidadId: _.get(this.order, "tblEntEntidad.entidadId"),
        pUsuarioId: _.get(this.order, "tblUsrUsuario.usuario"),
        pFechProcIn: this.order.fechaProceso,
        pFechProcFin: this.order.fechaProceso,
      });

      window.open(`${base}/frameset?${params.toString()}`, this.$t("OrderDetails.detail"), "height=300,width=500");
    },
  },
};
</script>