<template>
  <div class="box-container">
    <v-toolbar flat class="mb-5" color="transparent">
      <v-app-bar-nav-icon>
        <v-icon color="primary">mdi-cart-variant</v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title class="font-weight-bold">{{ $t("OrderDetails.detail") }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        outlined
        color="primary"
        @click="()=>$router.push(`/entities/${$route.params.entidadId}/account/orders`).catch(()=>{})"
        v-if="isAuthenticated"
      >
        {{ $t("OrderDetails.back") }}
      </v-btn>
    </v-toolbar>

    <n-status :order="order"></n-status>

    <v-row>
      <v-col cols="12">
        <v-card class="shadow-sm">
        <v-simple-table class="pa-5">
          <template v-slot:default>

            <thead>
              <tr>
                <th class="text-center">{{ $t("OrderDetails.image") }}</th>
                <th>{{ $t("OrderDetails.product") }}</th>
                <th class="text-right">{{ $t("OrderDetails.price") }}</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="item in details" :key="item.ocDtlId">
                <td>
                  <v-img style="margin: 5px auto 5px auto" width="50px" max-width="50px" :src="item.imagen || '/img/default_product_image.png'"></v-img>
                </td>
                <td>{{ item.cantidadCaja || 0 }} - {{ item.producto }}</td>
                <td class="text-right">$ {{ (item.precioUnitario || 0).toFixed(2) }}</td>
              </tr>

              <tr v-if="details && details.length == 0">
                  <td colspan="5" class="text-center caption">No se han encontrado registros!</td>
                </tr>
            </tbody>
          </template>
        
        </v-simple-table>
        </v-card>
      </v-col>
      
      <v-col cols="12" lg="6">
        <v-card class="shadow-sm">
          <v-card-title>{{ $t("OrderDetails.shippingAddress") }}</v-card-title>
          <v-card-text>
            <p>
              {{ order.direccion }}
              <br />
              {{ order.instrucciones }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      
      <v-col cols="12" lg="6">
        <v-card class="shadow-sm">
          <v-card-title>{{ $t("OrderDetails.summary") }}</v-card-title>

          <v-list>
            <v-list-item>
              <v-list-item-content>{{ $t("OrderDetails.summary") }}</v-list-item-content>
              <v-list-item-action>$ {{ (order.subtotal || 0).toFixed(2) }}</v-list-item-action>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>{{ $t("OrderDetails.discount") }}</v-list-item-content>
              <v-list-item-action>- $ {{ (order.descuento || 0).toFixed(2) }}</v-list-item-action>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>{{ $t("OrderDetails.tax") }}</v-list-item-content>
              <v-list-item-action>$ {{ (order.iva || 0).toFixed(2) }}</v-list-item-action>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>{{ $t("OrderDetails.ieps") }}</v-list-item-content>
              <v-list-item-action>$ {{ (order.montoIeps || 0).toFixed(2) }}</v-list-item-action>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-content>{{ $t("OrderDetails.total") }}</v-list-item-content>
              <v-list-item-action>$ {{ (order.total || 0).toFixed(2) }}</v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import NStatus from "../../../components/NStatus";
import { mapState } from 'vuex';

export default {
  components: {
    NStatus,
  },
  computed: {
    ...mapState({
      isAuthenticated: (state) => state.authentication.isAuthenticated,
    }),
  },
  data() {
    return {
      order: {},
      details: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const ocId = this.$route.params.ocId;

      let url = 'api/v1'

      if (this.isAuthenticated) {
        url += `/orders/${ocId}`
      }else{
        url += `/public/orders/${ocId}`
      }

      axios
        .get(url)
        .then((res) => {
          this.order = _.get(res, "data.data.order", {});
          this.details = _.get(res, "data.data.details", []);
        })
        .catch(() => {
          this.order = {};
          this.details = [];
        });
    }
  }
};
</script>