var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('base-card',{staticClass:"pa-4 text-center align-center",attrs:{"disabled":_vm.disableCards},on:{"click":function () {
            if (!_vm.disableCards) {
              _vm.openReport();
            }
          }}},[_c('v-icon',{attrs:{"large":"","color":_vm.disableCards ? '' : 'primary'}},[_vm._v("mdi-cart-variant")]),(!hover)?_c('p',{staticClass:"mb-0 mt-2 text-12 grey--text text--darken-1"},[_vm._v(" "+_vm._s(_vm.order.ocId)+" ")]):_c('p',{staticClass:"mb-0 mt-2 text-12 primary--text text-decoration-underline"},[_vm._v(" "+_vm._s(_vm.$t("OrderDetails.invoice"))+" ")])],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"3"}},[_c('base-card',{staticClass:"pa-4 text-center align-center",attrs:{"disabled":_vm.disableCards}},[_c('v-badge',{attrs:{"color":_vm.disableCards ? 'grey' : 'primary',"icon":_vm.confirmationIcon,"bordered":"","offset-x":"10","offset-y":"10"}},[_c('v-icon',{attrs:{"large":"","color":_vm.disableCards ? '' : 'primary'}},[_vm._v("mdi-package")])],1),(_vm.order.tblLgtEstatusOc)?_c('p',{staticClass:"mb-0 mt-2 text-12 grey--text text--darken-1"},[_vm._v(" "+_vm._s(_vm.order.tblLgtEstatusOc.estatusOc)+" ")]):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"3"}},[_c('base-card',{staticClass:"pa-4 text-center align-center",on:{"click":function () {
          if (
            _vm.order.tblLgtEstatusPago &&
            _vm.order.tblLgtEstatusPago.estatusPagoId == 'PP'
          ) {
            _vm.$router.push(("/orders/" + (_vm.order.ocId) + "/payments")).catch(function (){});
          }
        }}},[_c('v-badge',{attrs:{"color":"primary","icon":_vm.paymentIcon,"bordered":"","offset-x":"10","offset-y":"10"}},[_c('v-icon',{attrs:{"large":"","color":"primary"}},[_vm._v("mdi-credit-card-outline")])],1),(
          _vm.order.tblLgtEstatusPago &&
          _vm.order.tblLgtEstatusPago.estatusPagoId != 'PP'
        )?_c('p',{staticClass:"mb-0 mt-2 text-12 grey--text text--darken-1"},[_vm._v(" "+_vm._s(_vm.order.tblLgtEstatusPago.estatusPago)+" ")]):_c('p',{staticClass:"mb-0 mt-2 text-12 primary--text text-decoration-underline"},[_vm._v(" "+_vm._s(_vm.$t("OrderDetails.payOnline"))+" ")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"3"}},[_c('base-card',{staticClass:"pa-4 text-center align-center",on:{"click":function () {
          if (_vm.isAuthenticated) {
            _vm.$router.push(("/entities/" + (_vm.$route.params.entidadId) + "/account/orders/" + (_vm.order.ocId) + "/codi")).catch(function (){});
          }else{
            _vm.$router.push(("/orders/" + (_vm.order.ocId) + "/codi")).catch(function (){});
          }
        }}},[_c('v-icon',{attrs:{"large":"","color":"primary"}},[_vm._v("mdi-qrcode")]),_c('p',{staticClass:"mb-0 mt-2 text-12 grey--text text--darken-1"},[_vm._v(" "+_vm._s(_vm.$t("OrderDetails.codi"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }